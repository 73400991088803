import React, { FC } from "react";
import ProBan from "../../assets/images/choose-banner.png";
import Medal from "../../assets/images/medal.png";
import Test from "../../assets/images/test.png";
import Molecule from "../../assets/images/molecule.png";
import Customer from "../../assets/images/loyal-customer.png";
import {
  LazyLoadImage,
  LazyLoadComponent,
} from "react-lazy-load-image-component";

interface props {
  pageData: any;
}

const WhyChoose: FC<props> = ({ pageData }) => {
  return (
    <LazyLoadComponent>
      <div className="px-[15px] md:px-[27px] xl:px-[88px] lg:px-[50px] contain md:mb-[100px] mb-[50px] fadeInUp-animation5 contain">
        <div className="choose-bg w-full !bg-bg-grey rounded-[21px]  lg:px-[50px] md:py-[60px] py-[30px]  px-[15px] ">
          <div
            className="why-choose-text"
            dangerouslySetInnerHTML={{ __html: pageData.description }}
          ></div>
          <div className="flex md:flex-row flex-col  justify-between items-center ">
            <div className="flex flex-col  md:order-1 md:w-[30%] w-full justify-around items-start gap-4">
              <div className="flex gap-3">
                <div className="w-[70px] h-[70px] flex justify-center items-center rounded-full bg-primary pep-icon">
                  <LazyLoadImage
                    effect="blur"
                    src={pageData?.informationOneIcon?.node?.sourceUrl}
                    alt="Unmatched Quality"
                    loading="lazy"
                    width={56}
                    height={56}
                    className="w-[45px] h-[45px] object-contain"
                  />
                  {/* <img
                    src={pageData?.informationOneIcon?.node?.sourceUrl}
                    alt="Unmatched Quality"
                    loading="lazy"
                    width={56}
                    height={56}
                    className="w-[45px] h-[45px] object-contain"
                  ></img> */}
                </div>
                <div className="w-[70%]">
                  <h3 className="font-sofiaSemibold font-semiBold xl:text-[20px] text-[18px]">
                    {pageData?.informationOneTitle}
                  </h3>
                  <p className="font-sofiaRegular font-regular  xl:text-[17px] text-[14px]">
                    {pageData?.informationOneDescription}
                  </p>
                </div>
              </div>
              <div className="flex gap-3 md:mb-0 mb-4">
                <div className="w-[70px] h-[70px] flex justify-center items-center rounded-full bg-primary pep-icon">
                  <LazyLoadImage
                    src={pageData?.informationThreeIcon?.node?.sourceUrl}
                    alt="Unmatched Quality"
                    loading="lazy"
                    width={56}
                    height={56}
                    className="w-[45px] h-[45px] object-contain"
                  />
                  {/* <img
                    src={pageData?.informationThreeIcon?.node?.sourceUrl}
                    alt="Unmatched Quality"
                    loading="lazy"
                    width={56}
                    height={56}
                    className="w-[45px] h-[45px] object-contain"
                  ></img> */}
                </div>
                <div className="w-[70%]">
                  <h3 className="font-sofiaSemibold font-semiBold xl:text-[20px] text-[18px]">
                    {pageData?.informationThreeTitle}
                  </h3>
                  <p className="font-sofiaRegular font-regular  xl:text-[17px] text-[14px]">
                    {pageData?.informationThreeDescription}
                  </p>
                </div>
              </div>
            </div>
            <div className="md:order-2 order-3 flex flex-col  md:w-[40%] w-full why-img">
              <LazyLoadImage
                effect="blur"
                src={pageData.bannerImage?.node?.sourceUrl}
                width={400}
                height={300}
                className="w-full object-contain"
                alt="Why choose us"
              />
              {/* <img
              loading="lazy"
              src={pageData.bannerImage?.node?.sourceUrl}
              width={500}
              height={415}
              alt="Why choose us"
            ></img> */}
              <div className="md:mt-4">
                <h4 className="font-sofiaBold font-bold text-black xl:text-[25px] sm:text-[21px] text-[18px] xsm:text-center  text-left">
                  {pageData?.bottomInformationOne}
                  {pageData?.bottomInformationTwo}
                </h4>
              </div>
            </div>

            <div className="flex flex-col md:order-3  order-2  md:w-[30%] w-full justify-around gap-4 items-end">
              <div className="flex gap-3 md:flex-row-reverse">
                <div className="w-[70px] h-[70px] flex justify-center items-center rounded-full bg-primary pep-icon">
                  <LazyLoadImage
                    effect="blur"
                    src={pageData?.informationTwoIcon?.node?.sourceUrl}
                    alt="Unmatched Quality"
                    loading="lazy"
                    width={56}
                    height={56}
                    className="w-[45px] h-[45px] object-contain"
                  />
                  {/* <img
                    src={pageData?.informationTwoIcon?.node?.sourceUrl}
                    alt="Unmatched Quality"
                    loading="lazy"
                    width={56}
                    height={56}
                    className="w-[45px] h-[45px] object-contain"
                  ></img> */}
                </div>
                <div className="w-[70%] md:text-right text-left">
                  <h3 className="font-sofiaSemibold font-semiBold xl:text-[20px] text-[18px] ">
                    {pageData?.informationTwoTitle}
                  </h3>
                  <p className="font-sofiaRegular font-regular  xl:text-[17px] text-[14px]">
                    {pageData?.informationTwoDescription}
                  </p>
                </div>
              </div>
              <div className="flex gap-3 md:flex-row-reverse">
                <div className="w-[70px] h-[70px] flex justify-center items-center rounded-full bg-primary pep-icon">
                  <LazyLoadImage
                    effect="blur"
                    src={pageData.informationFourIcon?.node?.sourceUrl}
                    alt="Unmatched Quality"
                    loading="lazy"
                    width={56}
                    height={56}
                    className="w-[45px] h-[45px] object-contain"
                  />
                  {/* <img
                    src={pageData.informationFourIcon?.node?.sourceUrl}
                    alt="Unmatched Quality"
                    loading="lazy"
                    width={56}
                    height={56}
                    className="w-[45px] h-[45px] object-contain"
                  ></img> */}
                </div>
                <div className="w-[70%] md:text-right text-left">
                  <h3 className="font-sofiaSemibold font-semiBold xl:text-[20px] text-[18px]">
                    {pageData.informationFourTitle}
                  </h3>
                  <p className="font-sofiaRegular font-regular xl:text-[17px] text-[14px]">
                    {pageData.informationFourDescription}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LazyLoadComponent>
  );
};

export default WhyChoose;
