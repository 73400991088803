import React from "react";
import { graphql, useStaticQuery } from "gatsby";

export const getFaqs = () => {
	const data = useStaticQuery(graphql`
		query {
			allFaqs: allWpFaq(sort: {faqs: {position: ASC}}) {
				nodes {
					id
					faqs {
						question
						answer
						position
					}
				}
			}

			limitedFaqs: allWpFaq(limit: 5, sort: {faqs: {position: ASC}}) {
				nodes {
					id
					faqs {
						question
						answer
						position
					}
				}
			}
		}
	`);

	return data;
};
