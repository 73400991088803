import React, { FC, useEffect, useState } from "react";
import BannerTop from "./BannerTop";
import Link from "../../Link";

interface Props {
  pageData: any;
}

const Banner: FC<Props> = ({ pageData }) => {
  const bannerData = pageData.primaryBanner;
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Handle screen resize logic
    const handleResize = () => {
      setIsMobile(window.matchMedia("(max-width: 767px)").matches);
    };

    // Initial check
    handleResize();

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Image sources
  const desktopImageSrc = bannerData?.primaryBannerImage?.node?.sourceUrl || `${process.env.GATSBY_SITE_URL}/wp-content/uploads/2024/09/banner-pr-min.webp`;
  const mobileImageSrc = `${process.env.GATSBY_SITE_URL}/wp-content/uploads/2024/09/banner-pr-min-mob-1.webp`;

  return (
    <div className="px-[15px] md:px-[27px] xl:px-[88px] lg:px-[50px] contain">
      <BannerTop pageData={pageData} />
      <div className="banner-bg">
        <div className="lg:pt-[40px] lg:pb-[50px] md:pt-[42px] md:pb-[60] pt-[10px] pb-[30px] flex md:flex-row flex-col justify-center items-center z-10 relative">
          {/* Text section */}
          <div className="flex flex-col xl:w-1/2 lg:w-[60%] md:w-[60%] w-full justify-center min-h-[320px] banner-text md:order-1 order-2">
            <div
              className="min-h-[230px]"
              dangerouslySetInnerHTML={{
                __html: bannerData?.primaryBannerDescription,
              }}
            ></div>
            <Link
              preserveQueryParams={true}
              className="bg-black font-sofiaRegular font-regular md:mt-[10px] w-[205px] md:px-[20px] px-[40px] md:py-2 py-4 rounded-[50px] text-white h-[57px] text-[18px] hover:bg-primary flex items-center justify-center leading-[1]"
              href={
                bannerData?.primaryBannerButtonUrl?.url
                  ? bannerData?.primaryBannerButtonUrl?.url + location.search
                  : "/product-category/all-peptides/"
              }
            >
              {bannerData?.primaryBannerButtonText || "SHOP NOW"}
            </Link>
          </div>

          {/* Image section */}
          <div className="xl:w-1/2 lg:w-[40%] md:w-[40%] md:min-h-[250px] min-h-[160px] w-full lg:pl-2 md:order-2 order-1 flex md:justify-end justify-center md:items-end items-center">
            {!isMobile ? (
              <img
                src={desktopImageSrc}
                srcSet={`${desktopImageSrc} 1024w`}
                sizes="500px"
                width={500}
                height={409}
                className="object-contain h-[380px] min-h-[220px] ml-auto md:block hidden"
                alt="Spartan Banner"
              />
            ) : (
              <img
                src={mobileImageSrc}
                srcSet={`${mobileImageSrc} 600w`}
                width={354}
                height={160}
                sizes="354px"
                className="w-full object-contain min-h-[160px] h-[160px] md:hidden block"
                alt="Spartan Banner"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
