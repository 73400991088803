// src/components/ReviewSection.tsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReviewCard from './ReviewCard';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './ReviewModule.css';

import { Gallery, Item } from "react-photoswipe-gallery";
import { getReview } from '../../services/YotpoServices';
interface Review {
  image_url: string | null;
  score: number;
  title: string;
  content: string;
  product_url: string;
  created_at: string;
}

const ReviewSection: React.FC = () => {
  const [reviews, setReviews] = useState<Review[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const env = process.env.GATSBY_YOTPO_SECRET;
  const [firstActiveIndex, setFirstActiveIndex] = useState<number>(0); // Track the first active index
  useEffect(() => {
    const fetchReviews = async () => {
      try {

       const data:any = await getReview()
        setReviews(data.reviews);
        setLoading(false);
        // console.log(response)
      } catch (error) {
        console.error("Error fetching reviews:", error);
        setLoading(false);
      }
    };

    fetchReviews();
  }, []);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items:2
     
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      // partialVisibilityGutter: -10 
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items:1,
      //partialVisibilityGutter: -40 
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items:1,
      partialVisibilityGutter: 0 
    }
  };
  const handleAfterChange = (previousSlide: number, { currentSlide }: { currentSlide: number }) => {
    setFirstActiveIndex(currentSlide); // Update the active index after slide changes
  };
  // if (loading) {
  //   return <p>Loading...</p>;
  // }

  if (reviews.length === 0) {
    return null; // Return nothing if there are no reviews
  }
  return (
    <section className="px-[15px] md:px-[27px] xl:px-[88px] lg:px-[50px] mx-auto md:pb-[70px] pb-[80px] contain border-b-[14px] border-[#F6F6F6] ">
      <div className=" mx-auto  relative">
        <h2 className="xl:text-[65px] lg:text-[55px] text-[32px] font-sofiaMedium font-medium mb-6 lg:w-[50%] md:w-[80%] w-full z-0 leading-[1.2]">
          What our <span className="text-primary">customers</span> are saying
        </h2>
        <div className="w-full review-sec">
        <Carousel  responsive={responsive} partialVisible={true}  renderArrowsWhenDisabled={true}	 afterChange={handleAfterChange} >
          {reviews.map((review:any, idx) => (
            <div key={idx}  className={`${idx === firstActiveIndex ? 'slide1' : ''} ${idx === firstActiveIndex + 1 ? 'slide2' : ''}`}>
              <ReviewCard
                name={review.name}
                location="Unknown"
                title={review.title}
                review={review.content}
                rating={review.score}
                img={review.image_url || 'https://via.placeholder.com/150'}
              />
            </div>
          ))}
        </Carousel>
        </div>
      </div>
    </section>
  );
};

export default ReviewSection;
