import React, { FC } from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

const Faq: FC<{ faqs: any[] }> = ({ faqs }) => {
  return (
    <div>
      <Accordion allowZeroExpanded>
        {faqs?.length > 0 && faqs?.map((item: any, idx) => {
          return (
            <AccordionItem className='accordionItem' key={item.id || idx}>
              <AccordionItemHeading>
                <AccordionItemButton >
                  {item?.faqs?.question}
                  <span></span>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {item?.faqs?.answer}
                </p>
              </AccordionItemPanel>
            </AccordionItem>
          )
        })}
      </Accordion>
    </div>
  )
}

export default Faq