import React from "react";
import { getFeaturedProduct } from "../../services/woocommerceServices/Products";
import { useStaticQuery, graphql } from "gatsby";
import ProductCarousel from "./ProductCarousel";
export default function Featured() {
  const featuredProductData = useStaticQuery(graphql`
    query {
      allWcProducts(limit: 10, filter: {tags: {elemMatch: {name: {eq: "Featured"}}}, status: {eq: "publish"}}) {
        nodes {
          grouped_products
          id
          categories {
            id
            name
            slug
          }
          wordpress_id
          slug
          name
          price
          sale_price
          permalink
          on_sale
          short_description
          manage_stock
          stock_quantity
          stock_status
          variations
          images {
            src
            alt
            id
          }
        }
      }
    }
  `);
  // console.log(featuredProductData?.allWcProducts?.nodes.grouped_products)
  return (
    <div className="featured min-h-[340px]">
      <ProductCarousel
        isShowAddToCartBtn={false}
        productList={featuredProductData?.allWcProducts?.nodes}
        title={"Featured products"}
      />
    </div>
  );
}
