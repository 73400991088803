import React from "react";
import Faq from "./Faq";
import { getFaqs } from "../../services/FaqServices";
import FaqLogo from "../../assets/images/faq_logo.webp";
import FaqPro from "../../assets/images/faqPro.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";

const HomeFaq = () => {
  const { limitedFaqs }: any = getFaqs();
  return (
    <div className="px-[15px] md:px-[27px] xl:px-[88px] lg:px-[50px] md:mt-[80px] mt-[20px] md:mb-[100px] mb-[50px] home-faq fadeInUp-animation contain">
      <div className="flex lg:flex-row flex-col ">
        <div className="lg:w-1/2 w-full mt-[40px] lg:mb-0 mb-[40px]">
          <h2 className=" font-sofiaMedium font-medium xl:text-[65px] md:text-[52px] text-[32px] leading-[1.2] lg:mb-[40px] ">
            Have questions?
            <span className="text-primary block"> get answers</span>
          </h2>
          <Faq faqs={limitedFaqs?.nodes} />
        </div>
        <div className="lg:w-1/2 w-full flex lg:items-end  flex-col">
          <div className="bg-bg-grey py-6 pl-6 pr-3 flex justify-between rounded-[21px] slg:w-[80%] w-full relative ">
            <div className="flex flex-col md:w-2/3 w-1/2 z-10">
              <div className="md:w-[263px] w-[129px] md:h-[352px] h-[173px]">
                {/* <img
                  src={FaqLogo}
                  alt="Spartan Peptides"
                  width={263}
                  height={452}
                  className="w-full"
                ></img> */}
                <LazyLoadImage
                  effect="blur"
                  src={FaqLogo}
                  alt="Spartan Peptides"
                  className="w-full h-full "
                />
              </div>
              <h2 className="mt-4 font-sofiaSemibold font-semiBold xl:text-[25px] text-[20px]">
                Ready to elevate your research with high-quality peptides?
              </h2>
              <p className="mt-4 font-sofiaRegular font-regular text-[#424242] xl:text-[17px] text-[15px]">
                Visit Spartan Peptides today to explore our extensive range of
                research peptides designed to meet the highest industry
                standards.
              </p>
            </div>
            <div className=" top-0 right-[-10px] z-[5] xl:w-[300px] lg:w-[250px] w-[230px] h-full absolute">
              {/* <LazyLoadImage
                effect="blur"
                src={FaqPro}
                alt="Spartan Peptide"
                width={243}
                height={279}
                className="w-full h-full "
              /> */}
              <img
                src={FaqPro}
                alt="Spartan Peptide"
                width={243}
                height={279}
                loading="lazy"
                className="w-full h-full object-contain"
              ></img> 
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeFaq;
