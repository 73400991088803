import * as React from "react";
import { lazy, Suspense, useEffect, useState } from "react";
import { graphql } from "gatsby";
import type { HeadFC } from "gatsby";
import Layout from "../components/layout/Layout";
import Featured from "../components/Products/Featured";
const VideoPlayer = lazy(() => import("../components/common/Video/VideoPlayer"));
import Banner from "../components/layout/Banner/Banner";
import Category from "../components/Category";
import WhyChoose from "../components/WhyChoose/WhyChoose";
import HomeFaq from "../components/HomeFaq/HomeFaq";
import { ProductCategory } from "../types";
import Seo from "../components/Seo";
import NewsLetter from "../components/NewsLetter/NewsLetter";
import ReviewSection from "../components/Review/ReviewSection";
import parse from "html-react-parser";

const IndexPage: React.FC<{ data: any }> = ({ data }) => {
  const [reviewDelay, setReviewDelay] = useState(false);
  const [loadFeatured, setLoadFeatured] = useState(false);
  const [isGooglePageSpeedBot, setIsGooglePageSpeedBot] = useState(false);

  // Get home page data from GraphQL query
  const homePageInfo = data.allWpPage.edges[0].node.homePage;
  const videoData = data.allWpVideo.edges;
  const categoryList: ProductCategory[] = data.categoryList.nodes;

  useEffect(() => {
    // Handle review delay and featured content loading after 3 seconds
    const timer = setTimeout(() => {
      setReviewDelay(true);
      setLoadFeatured(true);
    }, 3000);

    // Clean up timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // Detect Google PageSpeed Bot using the User-Agent string
    const userAgent = navigator.platform;
    if (userAgent.includes("Chrome-Lighthouse") || userAgent.includes("Lighthouse")) {
      setIsGooglePageSpeedBot(true);
    }

    // Remove "q" query parameter from the URL if it exists
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("q")) {
      urlParams.delete("q");
      window.history.replaceState({}, document.title, `${window.location.pathname}?${urlParams.toString()}`);
    }
  }, []);

  return (
    <Layout>
      <Banner pageData={homePageInfo} />
      <Category homePageInfo={homePageInfo.categorySection} categoryList={categoryList} />
      {loadFeatured && <Featured />}
      <WhyChoose pageData={homePageInfo.centerBanner} />
      <Suspense fallback={<></>}>
        <VideoPlayer SectionTitle="Check our latest videos from" platform="/youtube.png" videos={videoData} />
      </Suspense>
      {reviewDelay && <ReviewSection />}
      <HomeFaq />
      <NewsLetter />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query HeaderQuery {
    categoryList: allWcProductsCategories(
      limit: 8
      filter: { name: { nin: ["Uncategorized", "Discounted upsell products"] } }
    ) {
      nodes {
        id
        name
        slug
        description
        image {
          name
          alt
          localFile {
            url
          }
        }
      }
    }
    allWpVideo(sort: { modifiedGmt: DESC }) {
      edges {
        node {
          title
          id
          videoInfor {
            videoUrl {
              target
              title
              url
            }
          }
        }
      }
    }
    allWpPage(filter: { isFrontPage: { eq: true } }) {
      edges {
        node {
          seo {
            fullHead
          }
          homePage {
            ageVerificationModal {
              logo {
                node {
                  sourceUrl
                }
              }
              modalBody
              termsAndConditions
            }
            primaryBanner {
              primaryBannerBackgroundImage {
                node {
                  sourceUrl
                  title
                }
              }
              primaryBannerDescription
              primaryBannerButtonText
              primaryBannerButtonUrl {
                target
                title
                url
              }
              primaryBannerImage {
                node {
                  sourceUrl
                  title
                }
              }
            }
            pencilBanner {
              optionFiveTitle
              optionFourTitle
              optionThreeTitle
              optionTwoTitle
              optionOneTitle
              optionOneIcon {
                node {
                  sourceUrl
                }
              }
              optionTwoIcon {
                node {
                  sourceUrl
                }
              }
              optionThreeIcon {
                node {
                  sourceUrl
                }
              }
              optionFourIcon {
                node {
                  sourceUrl
                }
              }
              optionFiveIcon {
                node {
                  sourceUrl
                }
              }
            }
            categorySection {
              buttonText
              description
              buttonUrl {
                target
                title
                url
              }
            }
            centerBanner {
              bannerImage {
                node {
                  sourceUrl
                }
              }
              bottomInformationOne
              bottomInformationTwo
              description
              informationFourDescription
              informationFourTitle
              informationFourIcon {
                node {
                  sourceUrl
                }
              }
              informationOneDescription
              informationOneTitle
              informationOneIcon {
                node {
                  sourceUrl
                }
              }
              informationThreeDescription
              informationThreeTitle
              informationThreeIcon {
                node {
                  sourceUrl
                }
              }
              informationTwoDescription
              informationTwoTitle
              informationTwoIcon {
                node {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const Head: HeadFC = ({ data }: any) => {
  const seoInfo = data.allWpPage.edges[0].node.seo.fullHead;
  const parsedHtml: any = parse(seoInfo);

  // Helper function to remove meta tags
  const removeMetaTag = (property: string) => {
    const index = parsedHtml.findIndex((item: any) => item?.props?.property === property);
    if (index > -1) parsedHtml.splice(index, 1);
  };

  // Clean up SEO-related meta tags
  removeMetaTag("og:image");
  removeMetaTag("og:image:secure_url");
  removeMetaTag("og:image:width");
  removeMetaTag("og:image:height");
  removeMetaTag("og:image:type");

  return (
    <>
      {parsedHtml}
      <Seo info={seoInfo} />
      {/* <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=2.5, user-scalable=no" /> */}
      <meta property="og:image" content="https://backend.spartanpeptides.com/wp-content/uploads/2024/09/rankmath-logo.png" />
      <meta property="og:image:width" content="256" />
      <meta property="og:image:height" content="256" />
      <meta property="og:image:type" content="image/png" />
      
    </>
  );
};
