import axios from "axios";

export const getReview = async () => {
  //   let config = {
  //     method: "get",
  //     maxBodyLength: Infinity,
  //     url: "https://api.yotpo.com/v1/apps/32WCW8rg0gbhiSy1IHEZjRuKH0UynB7Va83EdmlP/reviews?since_id=5&count=10&utoken=ejPXHHYzsVfFhSoea7f5kOFxqSYkGE1GHKEWbwjg",
  //   };

  //     await axios.request(config)

  let data = JSON.stringify({
    grant_type: "client_credentials",
    client_secret:process.env.GATSBY_YOTPO_SECRET ,
    client_id: process.env.GATSBY_YOTPO_APPKEY,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.yotpo.com/oauth/token",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  const token = await axios.request(config);
  // console.log(token.data.access_token, "access_token");

  let ReviewConfig = {
    method: "get",
    maxBodyLength: Infinity,
    url: `https://api.yotpo.com/v1/apps/${process.env.GATSBY_YOTPO_APPKEY}/reviews?since_id=5&count=5&utoken=${token.data.access_token}`,
  };

  const reviews = await axios.request(ReviewConfig);
  
  return { reviews: reviews.data.reviews };

  //   .then((response) => {
  //     console.log(JSON.stringify(response.data));
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //   });
};
